import React, { useState, useEffect } from "react"
import Axios from "axios"
const NEWS_API_URL =
  "https://www.ever-rise.co.jp/news/static/news-post.json"

const NewsList = () => {
  const [newsList, setNewsList] = useState([])

  useEffect(() => {
    Axios.get(NEWS_API_URL)
      .then(result => {
        setNewsList(result.data)
      })
      .catch(err => {})
  }, [])

  return (
    <div className="">
      {newsList.map((news, idx) => {
        return (
          <div className="flex mb-3 lg:mb-7" key={`news${idx}`}>
            <div className="w-20 lg:w-28 text-textGray-60 text-xs lg:text-base flex-shrink-0">
              {news.frontmatter.date}
            </div>
            <a
              href={`https://www.ever-rise.co.jp/news${news.fields.meta.slug}`}
              target="_blank"
              className="text-blue-950 font-bold text-xs lg:text-base flex-grow hover:opacity-80"
            >
              {news.frontmatter.title}
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default NewsList
